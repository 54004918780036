import React, { useState }  from "react"
import { useTranslation } from 'react-i18next';

import Layout from "../components/layout"
import SEO from "../components/seo"

import TransportCargoForm from "../components/transport-cargo-form";


const ForwardingOrdersPage = () => {
  const { t, i18n } = useTranslation("ForwardingIOrder");

  return(
    <Layout>
      <SEO title="Zlecenia Spedycyjne" />
      <section className="section" style={{ marginTop: "6rem" }}>
        <div className="container">
          <p className="title is-1 has-text-primary is-family-secondary">
            {t("FORWARDING_ORDER")}
          </p>
        </div>
        <TransportCargoForm />
      </section>
    </Layout>
  )
}

export default ForwardingOrdersPage
