import React from "react"
import { withTranslation } from "react-i18next"

class TransportCargoForm extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { t } = this.props

    return (
      <section className="section" style={{ paddingTop: "3rem" }}>
        <form
          className="container"
          method="POST"
          action="https://formspree.io/biuro@fastigo.pl"
        >
          <div
            className="columns"
            style={{ paddingBottom: "2rem", borderBottom: "1px solid #818181" }}
          >
            <div className="column">
              <div className="field">
                <label className="label">{t("FREE_FROM")}</label>
                <div className="control">
                  <input
                    className="input is-radiusless"
                    type="text"
                    name="wolny_od"
                    required
                  />
                </div>
                {/* <p class="help is-danger">This email is invalid</p> */}
              </div>
              <div className="field">
                <label className="label">{t("CITY")}</label>
                <div className="control">
                  <input
                    className="input is-radiusless"
                    type="text"
                    name="miejscowość"
                    required
                  />
                </div>
                {/* <p class="help is-danger">This email is invalid</p> */}
              </div>
            </div>
            <div className="column">
              <label className="label">{t("COUNTRY")}</label>
              <div
                className="select is-fullwidth is-radiusless"
                style={{ marginBottom: "0.75rem" }}
              >
                <select className="is-radiusless" name="kraj" required>
                  <option selected disabled >{t("COUNTRY")}</option>
                  <option value="B-BELGIA">B-BELGIA</option>
                  <option value="CZ-CZECHY">CZ-CZECHY</option>
                  <option value="D-NIEMCY">D- NIEMCY</option>
                  <option value="DK-DANIA">DK-DANIA</option>
                  <option value="E-HISZPANIA">E-HISZPANIA</option>
                  <option value="F-FRANCJA">F-FRANCJA</option>
                  <option value="FIN-FINLANDIA">FIN-FINLANDIA</option>
                  <option value="GB-ANGLIA">GB-ANGLIA</option>
                  <option value="IR-IRLANDIA">IR-IRLANDIA</option>
                  <option value="L-LUKSEMBURG">L-LUKSEMBURG</option>
                  <option value="NL-HOLANDIA">NL-HOLANDIA</option>
                  <option value="NO-NORWEGIA">NO-NORWEGIA</option>
                  <option value="PL-POLSKA">PL-POLSKA</option>
                  <option value="P-PORTUGALIA">P-PORTUGALIA</option>
                  <option value="SK-SŁOWACJA">SK-SŁOWACJA</option>
                  <option value="S-SZWECJA">S-SZWECJA</option>
                </select>
              </div>
              <div className="field">
                <label className="label">{t("ZIP")}</label>
                <div className="control">
                  <input
                    className="input is-radiusless"
                    type="text"
                    name="kod_pocztowy"
                  />
                </div>
                {/* <p class="help is-danger">This email is invalid</p> */}
              </div>
            </div>
          </div>
          <div
            className="columns"
            style={{ padding: "2rem 0", borderBottom: "1px solid #818181" }}
          >
            <div className="column">
              <div className="field">
                <label className="label">{t("FREE_TO")}</label>
                <div className="control">
                  <input
                    className="input is-radiusless"
                    type="text"
                    name="wolny_do"
                  />
                </div>
                {/* <p class="help is-danger">This email is invalid</p> */}
              </div>
              <div className="field">
                <label className="label">{t("CITY")}</label>
                <div className="control">
                  <input
                    className="input is-radiusless"
                    type="text"
                    name="miejscowość"
                  />
                </div>
                {/* <p class="help is-danger">This email is invalid</p> */}
              </div>
            </div>
            <div className="column">
              <label className="label">{t("COUNTRY")}</label>
              <div
                className="select is-fullwidth is-radiusless"
                style={{ marginBottom: "0.75rem" }}
              >
                <select className="is-radiusless" name="kraj" required>
                  <option selected disabled>{t("COUNTRY")}</option>
                  <option value="B-BELGIA">B-BELGIA</option>
                  <option value="CZ-CZECHY">CZ-CZECHY</option>
                  <option value="D-NIEMCY">D- NIEMCY</option>
                  <option value="DK-DANIA">DK-DANIA</option>
                  <option value="E-HISZPANIA">E-HISZPANIA</option>
                  <option value="F-FRANCJA">F-FRANCJA</option>
                  <option value="FIN-FINLANDIA">FIN-FINLANDIA</option>
                  <option value="GB-ANGLIA">GB-ANGLIA</option>
                  <option value="IR-IRLANDIA">IR-IRLANDIA</option>
                  <option value="L-LUKSEMBURG">L-LUKSEMBURG</option>
                  <option value="NL-HOLANDIA">NL-HOLANDIA</option>
                  <option value="NO-NORWEGIA">NO-NORWEGIA</option>
                  <option value="PL-POLSKA">PL-POLSKA</option>
                  <option value="P-PORTUGALIA">P-PORTUGALIA</option>
                  <option value="SK-SŁOWACJA">SK-SŁOWACJA</option>
                  <option value="S-SZWECJA">S-SZWECJA</option>
                </select>
              </div>
              <div className="field">
                <label className="label">{t("ZIP")}</label>
                <div className="control">
                  <input className="input is-radiusless" type="text" />
                </div>
                {/* <p class="help is-danger">This email is invalid</p> */}
              </div>
            </div>
          </div>
          <div
            className="columns"
            style={{ padding: "2rem 0", borderBottom: "1px solid #818181" }}
          >
            <div className="column">
              <div className="field">
                <label className="label">{t("LOAD")}</label>
                <div className="control">
                  <input
                    className="input is-radiusless"
                    type="text"
                    name="ładowność"
                    required
                  />
                </div>
                {/* <p class="help is-danger">This email is invalid</p> */}
              </div>
              <div className="field">
                <label className="label">{t("BODY")}</label>
                <div className="control">
                  <input
                    className="input is-radiusless"
                    type="text"
                    name="nadwozie"
                    required
                  />
                </div>
                {/* <p class="help is-danger">This email is invalid</p> */}
              </div>
              <div className="field">
                <label className="label">{t("SIZE")}</label>
                <div className="control">
                  <input
                    className="input is-radiusless"
                    type="text"
                    name="wymiary"
                  />
                </div>
                {/* <p class="help is-danger">This email is invalid</p> */}
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label">{t("LENGTH")}</label>
                <div className="control">
                  <input
                    className="input is-radiusless"
                    type="text"
                    name="długość"
                  />
                </div>
                {/* <p class="help is-danger">This email is invalid</p> */}
              </div>
              <div className="field">
                <label className="label">{t("WEIGHT")}</label>
                <div className="control">
                  <input
                    className="input is-radiusless"
                    type="text"
                    name="waga"
                  />
                </div>
                {/* <p class="help is-danger">This email is invalid</p> */}
              </div>
              <div className="field">
                <label className="label">{t("ADDITIONAL")}</label>
                <div className="control">
                  <input
                    className="input is-radiusless"
                    type="text"
                    name="wymagania_dodatkowe"
                  />
                </div>
                {/* <p class="help is-danger">This email is invalid</p> */}
              </div>
            </div>
          </div>
          <div className="columns" style={{ paddingTop: "2rem" }}>
            <div className="column">
              <div className="field">
                <label className="label">{t("PHONE")}</label>
                <div className="control">
                  <input className="input is-radiusless" type="text" required />
                </div>
                {/* <p class="help is-danger">This email is invalid</p> */}
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label">E-mail *</label>
                <div className="control">
                  <input
                    className="input is-radiusless"
                    type="email"
                    required
                  />
                </div>
                {/* <p class="help is-danger">This email is invalid</p> */}
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-3 is-offset-9">
              <button
                className="button is-normal is-primary is-fullwidth is-radiusless has-text-weight-bold"
                type="submit"
              >
                {t("SEND")}
              </button>
            </div>
          </div>
        </form>
      </section>
    )
  }
}

export default withTranslation("ForwardingIOrder")(TransportCargoForm)
